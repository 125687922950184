<template>
  <column-layout class="page" sticky-sides noRight>
    <!-- Left -->
    <template v-slot:left>
      <AdminMenu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget">
        <label class="label">
          {{ $t("common.dashboard") }}
        </label>

        <div class="card pa">
          <UsersStats />

          <v-divider class="my-10" />

          <PostsStats />

          <v-divider class="my-10" />

          <GroupsStats />
        </div>
      </div>
    </template>
  </column-layout>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu.vue";
import GroupsStats from "@/components/admin/dashboard/GroupsStats.vue";
import PostsStats from "@/components/admin/dashboard/PostsStats.vue";
import UsersStats from "@/components/admin/dashboard/UsersStats.vue";

export default {
  metaInfo: { title: "Dashboard" },

  components: { AdminMenu, GroupsStats, PostsStats, UsersStats },
};
</script>

<style lang="scss"></style>
