<template>
  <div class="posts-stats stats">
    <h4 class="primary--text mb-4">{{ $t("common.groups") }}</h4>

    <div class="stat-boxes">
      <StatBox
        v-for="(box, i) in boxes"
        :key="i"
        :label="box.label"
        :value="box.value"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import StatBox from "../StatBox.vue";

export default {
  components: { StatBox },

  data: () => ({
    loading: false,
    stats: {
      total_groups: null,
      total_public_groups: null,
      total_private_groups: null,
    },
  }),

  computed: {
    boxes() {
      return [
        {
          label: this.$t("dashboard.totalGroups"),
          value: this.stats.total_groups,
        },
        {
          label: this.$t("dashboard.totalPublicGroups"),
          value: this.stats.total_public_groups,
        },
        {
          label: this.$t("dashboard.totalPrivateGroups"),
          value: this.stats.total_private_groups,
        },
      ];
    },
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("admin/statsByType", "group")
      .then((stats) => {
        this.stats = stats;
      })
      .catch(() => {
        this.$toast.error("Error while fetching stats for groups.");
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style></style>
