<template>
  <div class="posts-stats stats">
    <h4 class="primary--text mb-4">{{ $t("posts.posts") }}</h4>

    <div class="stat-boxes">
      <StatBox
        v-for="(box, i) in boxes"
        :key="i"
        :label="box.label"
        :value="box.value"
        :loading="loading"
      />
    </div>

    <LineChart
      :title="$t('dashboard.newPosts')"
      type="post"
      height="250px"
      class="my-15"
    />
  </div>
</template>

<script>
import LineChart from "../charts/LineChart.vue";
import StatBox from "../StatBox.vue";

export default {
  components: { LineChart, StatBox },

  data: () => ({
    loading: false,
    stats: {
      total_posts_number: null,
      total_post_reshares: null,
      total_group_posts: null,
      total_feed_posts: null,
    },
  }),

  computed: {
    boxes() {
      return [
        {
          label: this.$t("dashboard.totalPosts"),
          value: this.stats.total_posts_number,
        },
        {
          label: this.$t("dashboard.totalReshares"),
          value: this.stats.total_post_reshares,
        },
        {
          label: this.$t("dashboard.totalGroupPosts"),
          value: this.stats.total_group_posts,
        },
        {
          label: this.$t("dashboard.totalFeedPosts"),
          value: this.stats.total_feed_posts,
        },
      ];
    },
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("admin/statsByType", "post")
      .then((stats) => {
        this.stats = stats;
      })
      .catch(() => {
        this.$toast.error("Error while fetching stats for posts.");
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style></style>
